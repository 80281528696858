@tailwind base;
@tailwind components;
@tailwind utilities;


/* width */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@font-face {
  font-family: 'rubik_bold';
  src: url('./fonts/Rubik-Bold.ttf') format('ttf');
}

@import url('https://fonts.googleapis.com/css?family=Rubik:wght@300;500;700');


body {
  margin: 0;
  font-family:'Rubik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-content-wrapper {
  border: 1px solid #CED4DA;
}

.table-content-wrapper table tr th {
  white-space: nowrap;
  background-color: #F3F3FF;
  color: #ffffff;
  padding: 10px !important;
  position: sticky;
  top: 0;
  width: 100%;
}

tr:nth-child(even) {
  background-color: #F3F3F3;
}

.table-content-wrapper thead tr th{
  color: #404040;
  font-size: 14px;
  line-height: 21px;
}

.table-content-wrapper table tr td {
  font-size: 14px;
  line-height: 32px;
}

.table-content-wrapper table tbody tr td{
  padding-left: 10px;
}

.table-content-wrapper table tbody tr:hover,
.table-content-wrapper table tbody tr.duplicate-email-phone:hover {
  background-color: #4285f4 !important;
  color: #ffffff !important;
}

.table-content-wrapper table th input[type="checkbox"],
.table-content-wrapper table td input[type="checkbox"] {
  position: unset;
  margin: 0;
}

.height-58 {
  max-height: 58vh;
}

.btn-circle {
  color: #525252 !important;
  width: 36px;
  height: 36px;
  transition: all 0.4s ease-in-out;
  position: relative;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 5px;
  margin-right: 3px !important;
  cursor: pointer;
}

.btn-circle:hover,
.btn-circle.active {
  color: #ffffff !important;
  background-color: transparent;
}

.btn-circle i {
  font-size: 16px;
  padding-top: 5px;
}

.btn-circle div {
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  transform: scale(0);
  z-index: -1;
}

.btn-circle:hover div,
.btn-circle.active div {
  transform: scale(1);
  opacity: 1;
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
      display: none;
  }
 /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
  }
}

.table-content-wrapper2 {
  border: 1px solid #CED4DA;
}

.table-content-wrapper2 table tr th {
  white-space: nowrap;
  background-color: #F3F3FF;
  color: #ffffff;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 13px;
  height: 38px;
  width: 100%;
}

.table-content-wrapper2 thead tr th{
  color: #404040;
  line-height: 21px;
}

.table-content-wrapper2 table tr td {
  font-size: 14px;
  height: 38px;
  line-height: 32px;
}

.table-content-wrapper2 table tbody tr td{
  padding-left: 10px;
}
